<template lang="pug">
Error(v-if="error", :error="error")
Loader(v-else-if="loading", :info="loading")
.pay(v-else-if="payment")
  .left
    img(alt="Delooye logo", src="@/assets/logo_full.png") 
  .right
    .purchase
      .content
        img(v-if="payment.image", :src="payment.image")
        h2 {{ payment.name }}
        h1 {{ Intl.NumberFormat(undefined, { style: 'currency', currency: 'EUR' }).format(payment.price) }}
        h3 {{ payment.description }}
      button(@click="checkout()") Proceed to Checkout
        i.fas.fa-arrow-right
  .test-mode(v-if='id.startsWith("test_")') 
    i.fas.fa-exclamation-circle
    span Let op: dit is een testmode-betaling
Error(v-else, :error="'Something went wrong, please try again later.'")
</template>

<script>
import Loader from "../components/Loader.vue";
import Error from "../components/Error.vue";
import axios from "axios";

export default {
  props: {
    id: String,
  },
  components: {
    Loader,
    Error,
  },
  data: function () {
    return {
      error: null,
      loading: null,
      payment: null,
    };
  },
  mounted() {
    this.getPayment();
  },
  methods: {
    checkout() {
      if (this.payment.url) {
        this.loading = "Redirecting...";
        window.location.href = this.payment.url;
      }
    },
    getPayment() {
      this.loading = "Initializing...";
      axios
        .post(`https://payment.delooye.com/api/${this.id}/start`)
        .then((res) => {
          if (res.data) {
            this.payment = res.data;
            if (res.data.status === "canceled" || res.data.status === "paid") {
              this.$router.push({ path: `/${this.id}/redirect` });
            }
          } else {
            this.error = "Something went wrong, please try again later.";
          }
        })
        .catch((e) => {
          if (e.response?.status) {
            if (e.response.status === 400) {
              this.error =
                "Invalid payment ID. Please restart the payment process.";
            } else if (e.response.status === 404) {
              this.error =
                "We could not find your payment. Please restart the payment process.";
            } else if (e.response.status === 409) {
              this.getPayment();
            } else {
              this.error = "Something went wrong, please try again later.";
            }
          } else {
            this.error = "Something went wrong, please try again later.";
          }
        })
        .finally(() => {
          this.loading = null;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.pay {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #424242;
}
.left {
  width: 55%;
  img {
    max-width: 60%;
  }
}
.right {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45%;
  height: 100%;
  background-color: #57b6b2;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.purchase {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 75%;
  width: 75%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  background-color: #f6f6f6;
  padding: 0 0 25px 0;
}
.content {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 64px;
  }

  h2 {
    font-size: 32px;
  }

  h3 {
    font-size: 24px;
    white-space: pre-line;
    font-weight: 300;
  }

  img {
    width: 40%;
  }

  * {
    margin: 20px;
  }
}

@media screen and (max-width: 1200px) {
  .left {
    display: none;
  }
  .right {
    width: 100%;
  }
  .purchase {
    width: 100%;
    max-width: 520px;
    height: unset;
    padding: 0;
  }
  .content {
    h1 {
      font-size: 48px;
    }
    h2 {
      font-size: 24px;
    }
    h3 {
      font-size: 16px;
      white-space: pre-line;
    }
  }
}
</style>